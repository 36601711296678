var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap js-standard-operation" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("标准作业编辑")]),
          ],
          1
        ),
        _c("div"),
      ]),
      _c("div", { staticClass: "js-common-content" }, [
        _c(
          "div",
          { staticClass: "module chapter" },
          [
            _c("div", { staticClass: "module-header vertical-box" }, [
              _c("label", [_vm._v("章节")]),
              _vm.modify
                ? _c(
                    "div",
                    { staticClass: "control-brns" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            effect: "dark",
                            content: "保存章节",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-check",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.saveSection.apply(null, arguments)
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            effect: "dark",
                            content: "添加章节",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addSection(true, "")
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "draggable",
              {
                attrs: {
                  tag: "ul",
                  group: "parent",
                  "ghost-class": "ghost",
                  list: _vm.sectionArr,
                  disabled: !_vm.modify,
                },
              },
              _vm._l(_vm.sectionArr, function (sec, index) {
                return _c(
                  "li",
                  {
                    key: sec.id,
                    staticClass: "level level1",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.getSectionStep(sec)
                      },
                      "v-drag-end": function ($event) {
                        return _vm.dragEnd()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title-root" }, [
                      _c("span", { staticClass: "index" }, [
                        _vm._v(_vm._s(index + 1) + " " + _vm._s(sec.title)),
                      ]),
                      _c("div", { staticClass: "count" }, [
                        _c("span", { class: _vm.modify ? "num" : "" }, [
                          _vm._v("(" + _vm._s(sec.step.length) + ")"),
                        ]),
                        _vm.modify
                          ? _c("span", [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addSection(false, sec)
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteSection(sec)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "draggable",
                      {
                        attrs: {
                          tag: "div",
                          group: "child",
                          "ghost-class": "ghost",
                          list: _vm.sectionArr[index].child,
                          disabled: !_vm.modify,
                        },
                      },
                      _vm._l(sec.child, function (subSec, subIndex) {
                        return _c(
                          "div",
                          {
                            key: subSec.id,
                            staticClass: "level level2",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.getSectionStep(subSec)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(index + 1) +
                                  "-" +
                                  _vm._s(subIndex + 1) +
                                  " " +
                                  _vm._s(subSec.title)
                              ),
                            ]),
                            _c("div", [
                              _c("span", { class: _vm.modify ? "num" : "" }, [
                                _vm._v("(" + _vm._s(subSec.step.length) + ")"),
                              ]),
                              _c("span", [
                                _vm.modify
                                  ? _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteSection(subSec)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "module process" },
          [
            _vm._m(0),
            _c(
              "el-row",
              { staticClass: "process-content-header" },
              [
                _c(
                  "el-col",
                  { staticClass: "custom-col", attrs: { span: 20 } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入章节名称",
                        size: "small",
                        disabled: !_vm.modify,
                      },
                      model: {
                        value: _vm.currentSection.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentSection, "title", $$v)
                        },
                        expression: "currentSection.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "custom-col-2", attrs: { span: 4 } },
                  [
                    _vm.modify
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btn",
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.saveOperation },
                          },
                          [_vm._v("保存章节")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "draggable",
              {
                staticClass: "module-content-list",
                attrs: {
                  tag: "ul",
                  list: _vm.stepsArr,
                  group: "section",
                  "ghost-class": "ghost",
                  disabled: _vm.allowChildProcessDrag,
                },
                on: { end: _vm.sectionReplace },
              },
              _vm._l(_vm.stepsArr, function (step, stepIndex) {
                return _c("li", { key: step.id }, [
                  _c("div", { staticClass: "item-header" }, [
                    _c("span", [
                      _c("i", { staticClass: "icon icon-pic" }),
                      _c("label", [_vm._v("步骤" + _vm._s(stepIndex + 1))]),
                      _c("span", [
                        _vm._v(
                          _vm._s(step.name.trim() ? step.name : "默认标题")
                        ),
                      ]),
                    ]),
                    _c(
                      "span",
                      [
                        !step.isEdit
                          ? [
                              _c("i", {
                                staticClass: "el-icon-edit",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleEditStep(step)
                                  },
                                },
                              }),
                              _vm.modify
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteStep(step)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  step.isEdit
                    ? _c("div", { staticClass: "item-edit" }, [
                        _c("p", { staticClass: "common-tip" }, [
                          _c("i", [_vm._v("*")]),
                          _vm._v("标题："),
                        ]),
                        _c(
                          "p",
                          [
                            _c("el-input", {
                              attrs: { size: "small", disabled: !_vm.modify },
                              model: {
                                value: step.name,
                                callback: function ($$v) {
                                  _vm.$set(step, "name", $$v)
                                },
                                expression: "step.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("p", [_vm._v("描述：")]),
                        _c(
                          "p",
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                size: "small",
                                disabled: !_vm.modify,
                              },
                              model: {
                                value: step.content,
                                callback: function ($$v) {
                                  _vm.$set(step, "content", $$v)
                                },
                                expression: "step.content",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _vm._v(" 时长："),
                            _c("el-input-number", {
                              staticClass: "num",
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                max: 99999,
                                size: "small",
                                disabled: !_vm.modify,
                              },
                              model: {
                                value: step.standardTime,
                                callback: function ($$v) {
                                  _vm.$set(step, "standardTime", $$v)
                                },
                                expression: "step.standardTime",
                              },
                            }),
                            _vm._v("S "),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          [
                            _vm._v(" 附件："),
                            step.fullUrl
                              ? _c("span", { staticClass: "step-img" }, [
                                  _vm.isVideo(step.fullUrl)
                                    ? _c("video", {
                                        staticClass: "video",
                                        attrs: {
                                          src: step.fullUrl,
                                          autoplay: "true",
                                          loop: "true",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: { src: step.fullUrl, alt: "" },
                                      }),
                                  _vm.modify
                                    ? _c("i", {
                                        staticClass: "el-icon-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteImage(stepIndex)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: { click: _vm.handleAttManage },
                                  },
                                  [_vm._v("选择附件")]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "common-tip" },
                          [
                            _c("i", [_vm._v("*")]),
                            _vm._v("是否拍照："),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: !_vm.modify },
                                model: {
                                  value: step.needPhoto,
                                  callback: function ($$v) {
                                    _vm.$set(step, "needPhoto", $$v)
                                  },
                                  expression: "step.needPhoto",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "common-tip" },
                          [
                            _c("i", [_vm._v("*")]),
                            _vm._v("是否必填："),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: !_vm.modify },
                                model: {
                                  value: step.required,
                                  callback: function ($$v) {
                                    _vm.$set(step, "required", $$v)
                                  },
                                  expression: "step.required",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "common-tip" },
                          [
                            _c("i", [_vm._v("*")]),
                            _vm._v("异常是否继续："),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: !_vm.modify },
                                model: {
                                  value: step.exceptionGoOn,
                                  callback: function ($$v) {
                                    _vm.$set(step, "exceptionGoOn", $$v)
                                  },
                                  expression: "step.exceptionGoOn",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "common-tip" },
                          [
                            _c("i", [_vm._v("*")]),
                            _vm._v("异常类型："),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择……",
                                  size: "small",
                                  disabled: !_vm.modify,
                                },
                                on: { change: _vm.getTypeTip },
                                model: {
                                  value: step.resultType,
                                  callback: function ($$v) {
                                    _vm.$set(step, "resultType", $$v)
                                  },
                                  expression: "step.resultType",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                            _c("span", { staticClass: "type-tip" }, [
                              _vm._v(_vm._s(_vm.getTypeTip(step.resultType))),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "p",
                          { staticClass: "type-box" },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 4,
                                placeholder: "请输入……",
                                size: "small",
                                disabled: !_vm.modify,
                              },
                              model: {
                                value: step.resultJson,
                                callback: function ($$v) {
                                  _vm.$set(step, "resultJson", $$v)
                                },
                                expression: "step.resultJson",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "submit-btns" },
                          [
                            _vm.modify
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveStep(step)
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticStyle: { width: "100px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.stepHeaderClick(step)
                                  },
                                },
                              },
                              [_vm._v("关闭")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              }),
              0
            ),
            _vm.modify
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "add-step",
                        attrs: { type: "primary", size: "small", block: "" },
                        on: { click: _vm.addStep },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _vm._v(" 步骤添加 "),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("att-manage", {
        attrs: { fileList: _vm.fileList },
        on: {
          attManageSearch: _vm.attManageSearch,
          receiveFileUrl: _vm.receiveFileUrl,
        },
        model: {
          value: _vm.attDialogVisible,
          callback: function ($$v) {
            _vm.attDialogVisible = $$v
          },
          expression: "attDialogVisible",
        },
      }),
      _c("section-delete", {
        on: { handleDelete: _vm.handleDeleteSection },
        model: {
          value: _vm.sectionDeleteDialogVisible,
          callback: function ($$v) {
            _vm.sectionDeleteDialogVisible = $$v
          },
          expression: "sectionDeleteDialogVisible",
        },
      }),
      _c("step-delete", {
        on: { handleDelete: _vm.handleDeleteStep },
        model: {
          value: _vm.stepDeleteDialogVisible,
          callback: function ($$v) {
            _vm.stepDeleteDialogVisible = $$v
          },
          expression: "stepDeleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "module-header" }, [
      _c("label", [_vm._v("测试过程")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
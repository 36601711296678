<template>
  <div class="js-common-wrap js-standard-operation">
    <div class="js-common-head">
      <div>
        <el-button
          class="back-btn"
          @click="$goBack"
          icon="el-icon-arrow-left"
          size="small"
          type="primary"
          plain
        ></el-button>
        <label class="label">标准作业编辑</label>
      </div>
      <div>
        <!-- <el-button v-if="modify" type="primary" size="small" @click="handleAttManage">附件管理</el-button>
        <el-button v-if="modify" class="btn" type="primary" size="small" @click="saveOperation">保存章节</el-button> -->
      </div>
    </div>
    <div class="js-common-content">
      <div class="module chapter">
        <div class="module-header vertical-box">
          <label>章节</label>
          <div class="control-brns" v-if="modify">
            <el-tooltip
              class="item"
              effect="dark"
              content="保存章节"
              placement="bottom"
              style="font-size: 20px"
            >
              <i class="el-icon-circle-check" @click.stop="saveSection"></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="添加章节"
              placement="bottom"
              style="font-size: 20px"
            >
              <i class="el-icon-plus" @click.stop="addSection(true, '')"></i>
            </el-tooltip>
          </div>
        </div>
        <!-- <ul class="parent-sortable">
           -->
        <draggable
          tag="ul"
          group="parent"
          ghost-class="ghost"
          :list="sectionArr"
          :disabled="!modify"
        >
          <!-- <draggable :list="sectionArr" ghost-class="ghost"> -->
          <li
            v-for="(sec, index) in sectionArr"
            :key="sec.id"
            class="level level1"
            @click.stop="getSectionStep(sec)"
            @v-drag-end="dragEnd()"
          >
            <div class="title-root">
              <span class="index">{{ index + 1 }} {{ sec.title }}</span>
              <div class="count">
                <span :class="modify ? 'num' : ''"
                  >({{ sec.step.length }})</span
                >
                <span v-if="modify">
                  <i
                    class="el-icon-plus"
                    @click.stop="addSection(false, sec)"
                  ></i>
                  <i
                    class="el-icon-delete"
                    @click.stop="deleteSection(sec)"
                  ></i>
                </span>
              </div>
            </div>
            <draggable
              tag="div"
              :group="'child'"
              ghost-class="ghost"
              :list="sectionArr[index].child"
              :disabled="!modify"
            >
              <!-- <div class="child-sortable nested-sortable"> -->
              <div
                class="level level2"
                v-for="(subSec, subIndex) in sec.child"
                :key="subSec.id"
                @click.stop="getSectionStep(subSec)"
              >
                <span
                  >{{ index + 1 }}-{{ subIndex + 1 }} {{ subSec.title }}</span
                >
                <div>
                  <span :class="modify ? 'num' : ''"
                    >({{ subSec.step.length }})</span
                  >
                  <span>
                    <i
                      v-if="modify"
                      class="el-icon-delete"
                      @click.stop="deleteSection(subSec)"
                    ></i>
                  </span>
                </div>
              </div>
              <!-- </div> -->
            </draggable>
          </li>
        </draggable>
        <!-- </ul> -->
      </div>
      <div class="module process">
        <div class="module-header">
          <label>测试过程</label>
        </div>
        <el-row class="process-content-header">
          <el-col :span="20" class="custom-col">
            <el-input
              v-model="currentSection.title"
              placeholder="请输入章节名称"
              size="small"
              :disabled="!modify"
            ></el-input>
          </el-col>
          <el-col :span="4" class="custom-col-2">
            <el-button
              v-if="modify"
              class="btn"
              type="primary"
              size="small"
              @click="saveOperation"
              >保存章节</el-button
            >
          </el-col>
        </el-row>
        <draggable
          tag="ul"
          class="module-content-list"
          :list="stepsArr"
          group="section"
          ghost-class="ghost"
          :disabled="allowChildProcessDrag"
          @end="sectionReplace"
        >
          <li v-for="(step, stepIndex) in stepsArr" :key="step.id">
            <div class="item-header">
              <span>
                <i class="icon icon-pic"></i>
                <label>步骤{{ stepIndex + 1 }}</label>
                <span>{{ step.name.trim() ? step.name : "默认标题" }}</span>
              </span>
              <span>
                <template v-if="!step.isEdit">
                  <i
                    class="el-icon-edit"
                    @click.stop="handleEditStep(step)"
                  ></i>
                  <i
                    v-if="modify"
                    class="el-icon-delete"
                    @click.stop="deleteStep(step)"
                  ></i>
                </template>
                <!-- <template v-else>
                  <el-button
                    v-if="modify"
                    type="primary"
                    size="small"
                    @click="saveStep(step)"
                    >确定</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="stepHeaderClick(step)"
                    >关闭</el-button
                  >
                </template> -->
              </span>
            </div>
            <div v-if="step.isEdit" class="item-edit">
              <p class="common-tip"><i>*</i>标题：</p>
              <p>
                <el-input
                  v-model="step.name"
                  size="small"
                  :disabled="!modify"
                ></el-input>
              </p>
              <p>描述：</p>
              <p>
                <el-input
                  v-model="step.content"
                  type="textarea"
                  size="small"
                  :disabled="!modify"
                ></el-input>
              </p>
              <p>
                时长：<el-input-number
                  v-model="step.standardTime"
                  class="num"
                  controls-position="right"
                  :min="0"
                  :max="99999"
                  size="small"
                  :disabled="!modify"
                ></el-input-number
                >S
              </p>
              <p>
                附件：<span v-if="step.fullUrl" class="step-img">
                  <video
                    v-if="isVideo(step.fullUrl)"
                    :src="step.fullUrl"
                    autoplay="true"
                    loop="true"
                    class="video"
                  ></video>
                  <img v-else :src="step.fullUrl" alt="" />
                  <i
                    v-if="modify"
                    class="el-icon-close"
                    @click="deleteImage(stepIndex)"
                  ></i>
                </span>
                <el-button
                  v-else
                  size="small"
                  type="primary"
                  @click="handleAttManage"
                  >选择附件</el-button
                >
              </p>
              <p class="common-tip">
                <i>*</i>是否拍照：<el-radio-group
                  v-model="step.needPhoto"
                  :disabled="!modify"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </p>
              <p class="common-tip">
                <i>*</i>是否必填：<el-radio-group
                  v-model="step.required"
                  :disabled="!modify"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </p>
              <p class="common-tip">
                <i>*</i>异常是否继续：<el-radio-group
                  v-model="step.exceptionGoOn"
                  :disabled="!modify"
                >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </p>
              <p class="common-tip">
                <i>*</i>异常类型：<el-select
                  v-model="step.resultType"
                  placeholder="请选择……"
                  size="small"
                  @change="getTypeTip"
                  :disabled="!modify"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <span class="type-tip">{{ getTypeTip(step.resultType) }}</span>
              </p>
              <p class="type-box">
                <el-input
                  v-model="step.resultJson"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入……"
                  size="small"
                  :disabled="!modify"
                ></el-input>
              </p>
              <div class="submit-btns">
                <el-button
                  style="width: 100px"
                  v-if="modify"
                  type="primary"
                  size="small"
                  @click="saveStep(step)"
                  >保存</el-button
                >
                <el-button
                  style="width: 100px"
                  size="small"
                  @click="stepHeaderClick(step)"
                  >关闭</el-button
                >
              </div>
            </div>
          </li>
        </draggable>
        <div v-if="modify">
          <el-button
            class="add-step"
            type="primary"
            size="small"
            block
            @click="addStep"
          >
            <i class="el-icon-plus"></i> 步骤添加
          </el-button>
        </div>
      </div>
    </div>
    <att-manage
      v-model="attDialogVisible"
      :fileList="fileList"
      @attManageSearch="attManageSearch"
      @receiveFileUrl="receiveFileUrl"
    ></att-manage>
    <section-delete
      v-model="sectionDeleteDialogVisible"
      @handleDelete="handleDeleteSection"
    ></section-delete>
    <step-delete
      v-model="stepDeleteDialogVisible"
      @handleDelete="handleDeleteStep"
    ></step-delete>
  </div>
</template>
<script>
import AttManage from "@/components/AttManage";
import SectionDelete from "@/components/CommonDelete";
import StepDelete from "@/components/CommonDelete";
// https://github.com/SortableJS/Vue.Draggable
import draggable from "vuedraggable";
// import Sortable from "sortablejs";

export default {
  name: "",
  components: {
    AttManage,
    SectionDelete,
    StepDelete,
    draggable,
  },
  data() {
    return {
      attDialogVisible: false,
      sectionDeleteDialogVisible: false,
      stepDeleteDialogVisible: false,
      options: [
        {
          label: "文字",
          value: 1,
        },
        {
          label: "选择",
          value: 2,
        },
        {
          label: "数值",
          value: 3,
        },
      ],
      typeTip: "提示信息",
      currentID: "",
      sectionArr: [],
      stepsArr: [],
      showEditFlag: false,
      currentSection: {},
      currentStep: "",
      newStep: {
        id: "",
        no: 0,
        name: "默认标题",
        content: "",
        standardTime: 0,
        fileUrl: "",
        needPhoto: 0,
        required: 0,
        exceptionGoOn: 0,
        resultType: 1,
        resultJson: "",
      },
      fileList: "",
      modify: false,
    };
  },
  mounted() {
    this.currentID = this.$route.query.id;
    this.modify = eval(this.$route.query.isModify);
    console.log(this.modify);
    this.initPage();
  },
  computed: {
    allowChildProcessDrag() {
      // console.log(
      //   this.stepsArr,
      //   this.stepsArr.every((item) => !item.isEdit)
      // );
      // 编辑 && 每一个都是关闭状态才让拖
      return !(this.modify && this.stepsArr.every((item) => !item.isEdit));
    },
  },
  methods: {
    // initDraggable() {
    //   // 父级拖拽，只有一个
    //   const parentDraggableEle = document.querySelector(".parent-sortable");
    //   // 子拖拽，有很多个；目前只允许同父范围拖拽
    //   const childrenDraggableEle = document.querySelectorAll(".child-sortable");
    //   // init parent draggable
    //   new Sortable(parentDraggableEle, {
    //     group: "parent",
    //     animation: 150,
    //     fallbackOnBody: true,
    //     swapThreshold: 0.65,
    //   });
    //   // Loop through each nested sortable element
    //   for (var i = 0; i < childrenDraggableEle.length; i++) {
    //     new Sortable(childrenDraggableEle[i], {
    //       group: "child" + i,
    //       animation: 150,
    //       fallbackOnBody: true,
    //       swapThreshold: 0.65,
    //     });
    //   }
    // },
    resetEdit() {
      this.stepsArr.forEach((item, index) => {
        this.stepsArr[index].isEdit = false;
      });
    },
    sectionReplace() {
      const data = this.stepsArr.map((item, index) => ({
        id: item.id,
        number: index,
      }));
      // submit
      this.$axios({
        method: "post",
        url: `api/csp/sop/v1/sopSectionStep/sort`,
        data: {
          data,
        },
      }).then(({ data }) => {
        if (!data.status) {
          this.$message({
            type: "success",
            message: "保存步骤成功！",
            duration: 1000,
          });
          // setTimeout(this.getSopSection,2000)
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    saveSection() {
      // format formData
      this.sectionArr = this.sectionArr.map((item, index) => ({
        ...item,
        number: index,
        child: item.child.map((cItem, cIndex) => {
          return {
            ...cItem,
            number: cIndex,
            parentId: item.id,
            parentNumber: index,
          };
        }),
      }));
      // submit
      this.$axios({
        method: "post",
        url: `api/csp/sop/v1/sopSection/sort`,
        data: {
          data: this.sectionArr,
        },
      }).then(({ data }) => {
        if (!data.status) {
          this.$message({
            type: "success",
            message: "保存章节成功！",
            duration: 1000,
          });
          // setTimeout(this.getSopSection,2000)
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    dragEnd() {
      console.log(arguments);
    },
    initPage() {
      this.getSopSection();
    },
    // 获取章节树
    getSopSection() {
      this.$axios({
        method: "get",
        url: `api/csp/sop/v1/sopSection/tree/${this.currentID}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.sectionArr = data.data;
          this.sectionArr.map((m) => {
            return (m.isNew = false);
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 获取章节步骤
    getSectionStep(item) {
      this.currentSection = item;
      if (!item.isNew) {
        this.$axios({
          method: "get",
          url: `api/csp/sop/v1/sopSectionStep/step/${item.id}`,
        }).then((response) => {
          let data = response.data;
          if (!data.status) {
            this.stepsArr = data.data;
            this.stepsArr.map((m) => {
              return (m.isEdit = false);
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
              duration: 1000,
            });
          }
        });
      }
    },
    // 章节添加
    addSection(level, sec) {
      let options = "";
      if (level) {
        options = {
          id: "",
          no: "",
          parentId: 0,
          parentNo: 0,
          sopId: this.currentID,
          step: [],
          title: "",
          child: [],
          isNew: true,
        };
        this.currentSection = options;
        this.sectionArr.push(options);
      } else {
        options = {
          id: "",
          no: "",
          parentId: sec.id,
          parentNo: sec.no,
          sopId: this.currentID,
          step: [],
          title: "",
          child: [],
          isNew: true,
        };
      }
      let tmp = {
        no: options.no,
        parentId: options.parentId,
        parentNo: options.parentNo,
        sopId: options.sopId,
        title: options.title,
      };
      this.saveAddSection(tmp);
    },
    // 添加章节保存
    saveAddSection(options) {
      this.$axios({
        method: "post",
        url: `api/csp/sop/v1/sopSection/`,
        data: {
          data: options,
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$message({
            type: "success",
            message: "添加章节成功！",
            duration: 1000,
          });
          this.getSopSection();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    saveOperation() {
      if (this.currentSection.id) {
        if (!this.currentSection.title.trim()) {
          this.$message({
            type: "error",
            message: "请输入章节名称！",
            duration: 1000,
          });
          return;
        }
        this.$axios({
          method: "put",
          url: `api/csp/sop/v1/sopSection/${this.currentSection.id}`,
          data: {
            data: {
              parentId: this.currentSection.parentId,
              sopId: this.currentSection.sopId,
              title: this.currentSection.title,
            },
          },
        }).then((response) => {
          let data = response.data;
          if (!data.status) {
            this.$message({
              type: "success",
              message: "章节保存成功！",
              duration: 1000,
            });
            this.getSopSection();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
              duration: 1000,
            });
          }
        });
      } else {
        this.$message({
          type: "error",
          message: "请选择你要修改的章节！",
          duration: 1000,
        });
      }
    },
    // 删除章节
    deleteSection(item) {
      this.currentSection = item;
      this.sectionDeleteDialogVisible = true;
    },
    handleDeleteSection() {
      this.$axios({
        method: "delete",
        url: `api/csp/sop/v1/sopSection/${this.currentSection.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.currentSection = {};
          this.sectionDeleteDialogVisible = false;
          this.getSopSection();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 编辑步骤
    handleEditStep(step) {
      this.currentStep = step;
      this.stepsArr.map((item, i) => {
        item.isEdit = item.id === step.id ? true : false
        this.$set(this.stepsArr, i, item)
      });
      // this.$forceUpdate();
    },
    // 保存步骤
    saveStep(step) {
      this.$axios({
        method: "put",
        url: `api/csp/sop/v1/sopSectionStep/${step.id}`,
        data: {
          data: {
            sopId: this.currentID,
            sopSectionId: this.currentSection.id,
            name: step.name,
            content: step.content,
            standardTime: step.standardTime,
            fullUrl: step.fullUrl,
            fileUrl: step.fileUrl,
            needPhoto: step.needPhoto,
            required: step.required,
            exceptionGoOn: step.exceptionGoOn,
            resultType: step.resultType,
            resultJson: step.resultJson,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.getSectionStep(this.currentSection);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 删除步骤
    deleteStep(item) {
      this.currentStep = item;
      this.stepDeleteDialogVisible = true;
    },
    handleDeleteStep() {
      this.$axios({
        method: "delete",
        url: `api/csp/sop/v1/sopSectionStep/${this.currentStep.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.stepDeleteDialogVisible = false;
          this.getSectionStep(this.currentSection);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 添加步骤
    addStep() {
      if (!this.currentSection.id) {
        this.$message({
          type: "error",
          message: "请先选择章节，再添加步骤！",
          duration: 1000,
        });
        return;
      }
      this.$axios({
        method: "post",
        url: `api/csp/sop/v1/sopSectionStep/`,
        data: {
          data: {
            sopId: this.currentID,
            sopSectionId: this.currentSection.id,
            no: "",
            name: "默认标题",
            content: "",
            standardTime: 0,
            fileUrl: "",
            needPhoto: 0,
            required: 0,
            exceptionGoOn: 0,
            resultType: 1,
            resultJson: "",
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.$message({
            type: "success",
            message: "步骤创建成功！",
            duration: 1000,
          });
          this.getSopSection();
          this.getSectionStep(this.currentSection);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 附件管理
    handleAttManage() {
      this.attDialogVisible = true;
      this.getFileList({
        name: "",
        type: 1,
      });
    },
    getFileList(params) {
      this.$axios({
        method: "get",
        url: "api/csp/sop/v1/sopAttach/list",
        params: params,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.fileList = data.data;
          this.fileList.forEach((item) => {
            item.isCur = false;
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    attManageSearch(params) {
      this.getFileList(params);
    },
    receiveFileUrl(file) {
      this.currentStep.fileUrl = file.url;
      this.currentStep.fullUrl = file.fullUrl;
    },
    getTypeTip(type) {
      if (type == 1) {
        return "请输入文本类型异常信息";
      } else if (type == 2) {
        return "请输入下拉格式文本，按”，“切割，例如： a，b，-c, 带-表示异常";
      } else if (type == 3) {
        return "请输入数值范围，中间加~，例如5~10，输入5~10之外代表异常";
      }
    },
    deleteImage(i) {
      this.stepsArr[i].fullUrl = "";
      this.stepsArr[i].fileUrl = "";
    },
    stepHeaderClick(step) {
      this.stepsArr.map((item, i) => {
        item.isEdit = false
        this.$set(this.stepsArr, i, item)
      });
      // step.isEdit = false;
      // this.$forceUpdate();
    },
    isVideo(url) {
      const arr = url.split(".");
      return arr[arr.length - 1] === "mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standardOpe.scss";
.control-brns {
  i {
    cursor: pointer;
  }
  .el-icon-circle-check {
    margin-right: 10px;
  }
}
.video {
  width: 120px;
  height: 120px;
}
.vertical-box {
  display: flex;
  align-items: baseline;
  label {
    margin: 0;
  }
}
.custom-col {
  margin-top: 10px;
}
.submit-btns {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border-bottom: 1px solid #dcdfe6;
}
// .custom-col-2{
//   display: flex;
//   justify-content: end;
// }
</style>
